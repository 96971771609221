<template>
  <FocusTrap>
    <div class="card">

      <div class="card-header header-elements-inline" style="background-color: lightgrey;padding: 10px;">
      <h5 class="card-title">Warping Report</h5>
      <div class="header-elements">
        <div class="list-icons">
<!--          <a class="list-icons-item" data-action="modal"></a>-->
          <a class="list-icons-item" data-action="reload" @click="loadData"></a>
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>


      <table class="table table-togglable table-hover footable-loaded footable tablet breakpoint">
        <thead>
        <tr>
          <th class="footable-visible footable-first-column">Date</th>
          <th data-hide="phone" class="footable-visible">Shift</th>
          <th class="footable-visible text-right">Production In Kgs</th>
          <th class="footable-visible">Quality</th>
          <th class="footable-visible">Color</th>
          <th class="footable-visible text-right">No of Beams</th>
          <th class="footable-visible text-right">Yarn Waste</th>
          <!--          <th class="footable-visible text-right">Lumps</th>-->
<!--          <th class="footable-visible text-right">Raw Met Excess</th>-->
        </tr>
        </thead>
      <tbody>
        <tr v-for="det in deliveries">
          <td class="footable-visible footable-first-column">{{det.date | dateFormatter}}</td>
          <td class="footable-visible">{{det.shf_cd | shiftFormatter}}</td>
          <td class="footable-visible text-right"> <a href="#"> {{det.prod_wgt | weightFormat}} </a></td>
          <td class="footable-visible">{{det.quality.name}}</td>
          <td class="footable-visible">{{det.color.name}}</td>
          <td class="footable-visible text-right">{{det.qty}}</td>
          <td class="footable-visible text-right">{{det.yarn_waste_wgt| weightFormat}}</td>
<!--          <td class="footable-visible text-right">{{det.lumps_wgt| weightFormat}}</td>-->
<!--          <td class="footable-visible text-right">{{det.raw_mat_excess| weightFormat}}</td>-->
        </tr>

      </tbody>
    </table>

      <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="420" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
            <h3 class="card-title">Filter</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-2 col-form-label">From:</label>
              <div class="col-md-4">
                <input type="date" class="form-control" ref="from_date"  v-model="entry_from" :max="upto_date" />
              </div>

              <label class="col-md-2 col-form-label">Upto</label>
              <div class="col-md-4">
                <input type="date" class="form-control" ref="upto_date"  v-model="entry_upto" :min="from_date" />
              </div>
            </div>


            <div class="form-group row">
              <label class="col-md-2 col-form-label">Quality:</label>
              <div class="col-md-10">
                <select id="cmbQuality" class="form-control" v-model="qua" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in qualities" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>


            <div class="form-group row">
              <label class="col-md-2 col-form-label">Color:</label>
              <div class="col-md-10">
                <select class="form-control" v-model="col" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in colors" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label">Machine:</label>
              <div class="col-md-10">
                <select class="form-control" v-model="mac" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in machines" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label">Employee:</label>
              <div class="col-md-10">
                <select class="form-control" v-model="emp" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in emps" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

    </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'MachineWiseDelivery',
    store,
    components: {

    },
    data () {
      return {
        deliveries: [],
        qualities: [],
        colors:[],
        machines: [],
        emps: [],
        entry_from: moment().format('YYYY-MM-DD'),
        entry_upto: moment().format('YYYY-MM-DD'),
        upto_date:moment().format('YYYY-MM-DD'),
        from_date:moment().format('YYYY-MM-DD'),
        col:0,
        qua:0,
        mac:0,
        emp:0,
      }
    },
    mounted () {
      const self = this;
      this.$data.mytable = $('.table');

      this.loadQualities();
      this.loadColors();
      this.loadMachines();
      this.loadEmployees();
      this.showFilterWindow();

      $('filter-window').onShow(function (){
        self.refs.from_date.focus();
      });

      // this.loadData();
    },
    filters:{
      weightFormat (value) {
        if( value !== 0)
          return value.toFixed(3);
        else
          return '';
      },
      dateFormatter(val){
        try {
          return val.slice(0, 10).split('-').reverse().join('-');
        } catch (ex) {

        }
        return '';
      },
      shiftFormatter(val){
        try {
          if(val == 2){
            return 'Day';
          }else if(val == 3){
            return 'Night';
          }
          return val;
        } catch (ex) {

        }
        return '';
      }
    },
    methods:{
      closeWindow(){
        this.$router.push('/');
      },
      showFilterWindow(){
        this.$modal.show('filter-window');
      },
      closeThis(){
        this.$emit('warpinggreport_window_closed');
        if(this.voucher.code > 1){
          this.$modal.hide('warpinggreport_window');
        }else{
          this.$router.push("/home")
        }
      },
      loadQualities() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        self.qualities = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){
            if(_.isArray(resp.data)){
              self.qualities = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadColors() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        self.colors = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/colors`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){
            if(_.isArray(resp.data)) {
              self.colors = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadMachines() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.machines = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/2`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.machines = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadEmployees() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.emps = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( resp.data != null){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      loadData() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        this.$modal.hide('filter-window');
        self.$data.deliveries = [];
        // alert(`${process.env.VUE_APP_ROOT_API}api/report/warping/report?col=${self.col}&qua=${self.qua}&from=${self.entry_from}&upto=${self.entry_upto}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/report/warping/report?col=${self.col}&qua=${self.qua}&from=${self.entry_from}&upto=${self.entry_upto}`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){
            console.log(JSON.stringify(resp.data));
            self.$data.deliveries = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


      },
    }
  }
</script>

<style scoped>

</style>
